import '../css/fonts.css'
import '../css/plugins.css'
import '../css/style.css'
import '../css/theme.css'
import '../css/custom.css'

import './custom.js'
import './functions.js'
import './jquery.js'
import './plugins.js'
import './pageloader.init.js'
import './particles.js'
import './particles-dots.js'